class Copy

  @fallbackCopyToClipboard: (copyable, action) ->
    textArea = document.createElement('textarea')
    textArea.value = @copyableText(copyable)
    document.body.appendChild textArea
    textArea.focus()
    textArea.select()
    try
      successful = document.execCommand('copy')
      if successful then @toggleCopyButton(button)
    catch err
      console.error 'Fallback: Oops, unable to copy', err
      $('html').addClass('state--copy-not-supported')
    document.body.removeChild textArea
    return

  @copyToClipboard: (copyable, action) ->
    if !navigator.clipboard
      fallbackCopyTextToClipboard copyable, action
      return
    navigator.clipboard.writeText( @copyableText(copyable)).then (=>
      console.log 'Async: Copying to clipboard was successful!'
      @toggleCopyButton(action)
      return
    ), (err) ->
      console.error 'Async: Could not copy text: ', err
      return
    return

  @copyableText: (copyable) ->
    copyable.find('.target').html()

  @toggleCopyButton: (button) ->
    button.html("copied")
    button.css({"background-color": "#31b995", "border-color": "#31b995", "color": "white", cursor: "default"})

  @canCopy: () ->
    document.queryCommandSupported('copy')

ready = ->
  if Copy.canCopy
    $('body').on 'click', '.copy', (e) ->
      Copy.copyToClipboard($(this).closest('.copyable'),$(this))
      e.preventDefault()
  else
    $('html').addClass('state--copy-not-supported')


document.addEventListener 'turbolinks:load', ready

