document.addEventListener("turbolinks:load", function () {
  // var supportsPassive = false;
  // try {
  //   var opts = Object.defineProperty({}, 'passive', {
  //     get: function () {
  //       supportsPassive = true;
  //     }
  //   });
  //   window.addEventListener("testPassive", null, opts);
  //   window.removeEventListener("testPassive", null, opts);
  // } catch (e) { }

  // let touchstartX = 0;
  // let touchendX = 0;

  // const gestureZone = document.getElementById('card');
  // console.log("gesturezone.... ");
  // console.log(gestureZone);
  // if (gestureZone == null) {
  //   console.log("returning");
  //   return;
  // }

  // gestureZone.addEventListener('touchmove', preventMove, supportsPassive ? { passive: false } : false);

  // gestureZone.addEventListener('touchstart', function (event) {
  //   touchstartX = event.changedTouches[0].screenX;
  //   touchstartY = event.changedTouches[0].screenY;
  // }, supportsPassive ? { passive: true } : false);

  // gestureZone.addEventListener('touchend', function (event) {
  //   touchendX = event.changedTouches[0].screenX;
  //   touchendY = event.changedTouches[0].screenY;
  //   handleGesture();
  // }, supportsPassive ? { passive: true } : false);

  // function handleGesture() {
  //   if ((touchendX < touchstartX) && (touchstartX - touchendX >= 40)) { // swipe left
  //     if (typeof swipedLeft === "function") {
  //       swipedLeft()
  //     }
  //   }

  //   if ((touchendX > touchstartX) && (touchendX - touchstartX >= 40)) { // swipe right
  //     if (typeof swipedRight === "function") {
  //       swipedRight()
  //     }
  //   }
  // }

  // function preventMove(e) {
  //   const threshold = 5;

  //   touchX = e.changedTouches[0].screenX - touchstartX;

  //   // Disable vertical scrolling when you start swiping horizontally.
  //   if (Math.abs(touchX) > threshold) {
  //     e.preventDefault();
  //     e.returnValue = false;
  //     return false;
  //   }
  // }
})
