document.addEventListener("turbolinks:load", function () {
  // Shows text input when checkbox is checked
  $(".web-share").click(function (e) {
    e.preventDefault();
    if (navigator.share) {
      // Web Share API is supported - use native
      var url = document.querySelector('link[rel=canonical]') ? document.querySelector('link[rel=canonical]').href : document.location.href;
      navigator.share({
        title: $(this).data("share-text"),
        url: url
      }).then(() => {
        console.log('Thanks for sharing!')
      })
    } else {
      // Fallback
      console.log("clicked share button: " + $(this).data("share-text"))
      $(".dialog--share").addClass('state--open')
      $(this).addClass("hidden")
    }
  });

  $(".dialog--share__close").click(function () {
    $(".dialog--share").removeClass('state--open')
    $(".web-share").removeClass("hidden")
  });

});
