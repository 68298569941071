is_touch_device = ->
  'ontouchstart' of window or navigator.maxTouchPoints
  # works on IE10/11 and Surface



ready = ->
  if is_touch_device()
    $('html').addClass 'touch'
  else
    $('html').addClass 'no-touch'

document.addEventListener 'turbolinks:load', ready
