isSubsetOf = (set, subset) ->
  i = 0
  while i < set.length
    if subset.indexOf(set[i]) == -1
      return false
    i++
  true

filter_events = ->
  filters = $('.list--filter [data-event-filter]')
  cards   = $('.listing--events-primary__events [data-categories]')

  filters.on 'click', (e) ->
    currentFilter = $(this)
    currentFilterCategory = currentFilter.attr('data-event-filter')

    if currentFilter == 'all'
      filters.removeClass('active')
      currentFilter.addClass('active')

      cards.fadeOut().promise().done ->
        cards.fadeIn()
    else
      $('[data-event-filter="all"]').removeClass('active')
      currentFilter.toggleClass('active')

      active_filters = $('.item--filter.active')
      active_filter_values = active_filters.map(->
        $(this).attr('data-event-filter')
      ).sort() # array

      # console.log('active_filter_values: ' + active_filter_values.get())

      cards.fadeOut().promise().done ->
        if active_filter_values.get().includes("all")
          # Handle filter all being selected with other filters
          filters.removeClass('active')
          $('[data-event-filter="all"]').addClass('active')
          cards.fadeOut().promise().done ->
            cards.fadeIn()

        else if active_filters.length == 1
          # Handle one single filter
          active_filters.each ->
            filter = $(this).attr('data-event-filter')
            cards.each ->
              categories = $(this).attr('data-categories')
              if categories.split(' ').indexOf(filter) != -1
                $(this).fadeIn()

        else if active_filters.length > 1
          # Handle multiple filters
          cards.each ->
            categories = $(this).attr('data-categories').split(' ').sort()

            # console.log('categories: ' + categories)

            if isSubsetOf(active_filter_values, categories)
              $(this).fadeIn()

        else
          # If there are no active filters fallback to All
          filters.removeClass('active')
          $('[data-event-filter="all"]').addClass('active')
          cards.fadeOut().promise().done ->
            cards.fadeIn()

ready = ->
  filter_events()

document.addEventListener 'turbolinks:load', ready
