class Analytics

  @track_click: (clicked_element) ->
    event = clicked_element.data("tracked-event")
    uid = clicked_element.data("tracked-event-uid")
    properties = clicked_element.data("tracked-event-properties")
    $.ajax
      url: "/track_click"
      beforeSend: (xhr) ->
        xhr.setRequestHeader 'X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')
      type: 'POST'
      data: {event: event, uid: uid, properties: properties}
      dataType: 'json'
      success: (data) ->
        console.log data

ready = ->
  console.log "loading trackable"
  $('body').on 'click', '.trackable', (e) ->
    console.log "clicked on trackable element"
    Analytics.track_click($(this))

document.addEventListener 'turbolinks:load', ready
