class Revealable

  toggle_revealed: (target) ->
    reveal_id = $(target).data('reveals-id')
    revealed_item = $("[data-revealed-by='#{reveal_id}']")
    console.log "toggle revealed...."
    console.log "revealer close open: #{revealed_item.data('revealer-close-open') }"
    $('#' + $(revealed_item).data('revealer-scope-id') + ' .revealer').removeClass("active")
    if revealed_item.data('revealer-close-open') == true && revealed_item.hasClass('revealed') != true
      console.log "close open revealed...."
      console.log '#' + $(revealed_item).data('revealer-scope-id')
      $(revealed_item).siblings().removeClass('revealed').addClass('revealable');
      # $('#' + $(revealed_item).data('revealer-scope-id') + ' .revealed').removeClass('revealed').addClass('revealable');
    revealed_item.toggleClass('revealed')
    revealed_item.toggleClass('revealable')
    $(target).toggleClass("active")
    if $(target).data('inverse-text') != 'undefined' then @toggle_revealer_text(target)

  scroll_to_revealed: ->
    revealed_param = getUrlParameter("revealed")
    target = $("[data-revealed-by='#{revealed_param}']")
    return if target.length == 0
    scrollTop     = $(window).scrollTop()
    elementOffset = target.offset().top
    distance      = (elementOffset - scrollTop)
    $('html,body').animate { scrollTop: distance }, 500

  toggle_revealer_text: (target) ->
    inverse_text = $(target).data('inverse-text')
    current_text = $(target).html()
    $(target).html(inverse_text)
    $(target).data('inverse-text', current_text)

  getUrlParameter = (sParam) ->
    sPageURL = window.location.search.substring(1)
    sURLVariables = sPageURL.split('&')
    sParameterName = undefined
    i = undefined
    i = 0
    while i < sURLVariables.length
      sParameterName = sURLVariables[i].split('=')
      if sParameterName[0] == sParam
        return if sParameterName[1] == undefined then true else decodeURIComponent(sParameterName[1])
      i++
    return

ready = ->
  revealable = new Revealable
  revealable.scroll_to_revealed()
  $(document).off('click', '.revealer')
  $(document).on 'click', '.revealer', (e) ->
    e.preventDefault()
    revealable.toggle_revealed(this)
  return

document.addEventListener 'turbolinks:load', ready()
